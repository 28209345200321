//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GetAppBadges from '@/components/GetApp/GetAppBadges';
import GetAppPopup from "./__GetAppPopup";
export default {
  name: 'GetAppPopupDesktop',
  components: {
    'qr-code': function qrCode() {
      return import(
      /* webpackChunkName: "qr-code" */
      'qrcode.vue');
    },
    'app-get-app-badges': GetAppBadges,
    'app-get-app-popup': GetAppPopup
  },
  computed: {
    nativeApp: function nativeApp() {
      return this.$store.$app.nativeApp;
    },
    getQRValue: function getQRValue() {
      return "".concat(window.location.origin, "/app/");
    }
  },
  methods: {
    closePopup: function closePopup() {
      this.$store.dispatch('modals/setGetAppModalDesktop', {
        isOpen: false
      });
    }
  }
};